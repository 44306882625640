import {
  EventsByFilterInput,
  EVENTS_PAGE_SIZE,
  State,
  TEventsListItem,
  useInfiniteEventsQuery,
} from '@sim-admin-frontends/data-access';
import { Error, SpinnerWrapper } from '@sim-admin-frontends/ui-shared';
import {
  BACKEND_FORMAT,
  formatISODate,
  useOffsetTablePaging,
} from '@sim-admin-frontends/utils-shared';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortingRule } from 'react-table';

import { usePlaceInfo } from '../../../contexts/placeContext';
import { useAuthInfo } from '../../../contexts/userContext';
import { TUnpublishContainerProps } from '../../../types/TUnpublish';
import VideoTutorialBanner from '../../videoTutorials/VideoTutorialBanner';
import EventsView from './EventsView';
import { createTableColumns, DEFAULT_SORTING_GQL, getGqlSorting, getTableSorting } from './helper';

interface EventsViewContainerProps extends TUnpublishContainerProps {
  state: State;
  searchFilter?: string;
}

const EventsViewContainer: FC<EventsViewContainerProps> = ({
  postsToUnpublish,
  onUnpublishSelect,
  isUnpublishMutationSettled,
  state,
  searchFilter,
}) => {
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState(DEFAULT_SORTING_GQL);
  const { institutionUuid } = useAuthInfo();
  const { places } = usePlaceInfo();

  const institutionId = institutionUuid || '';

  const filter: EventsByFilterInput = useMemo(
    () => ({
      institutions: [institutionId],
      fromDatetime: formatISODate('01/01/1970', BACKEND_FORMAT),
      state,
      text: searchFilter || undefined,
    }),
    [institutionId, state, searchFilter],
  );

  const { fetchNextPage, hasNextPage, data, isError, isLoading, isFetchingNextPage, refetch } =
    useInfiniteEventsQuery(filter, orderBy);

  useEffect(() => {
    if (isUnpublishMutationSettled) {
      refetch();
    }
  }, [isUnpublishMutationSettled]);

  const columns = createTableColumns(
    t,
    refetch,
    state,
    places?.[0].timezoneCode,
    postsToUnpublish,
    onUnpublishSelect,
  );

  const { currentPageIndex, maxPageIndex, onPreviousPress, onNextPress, setCurrentPageIndex } =
    useOffsetTablePaging(fetchNextPage, EVENTS_PAGE_SIZE, hasNextPage);

  const onSortingChanged = useCallback(
    (newSorting: SortingRule<TEventsListItem>[]) => {
      setOrderBy(getGqlSorting(newSorting));
      setCurrentPageIndex(0);
    },
    [setCurrentPageIndex],
  );

  useEffect(() => {
    setCurrentPageIndex(0);
  }, [institutionUuid]);

  if (isError) {
    return (
      <>
        <SpinnerWrapper>
          <Error caption={t('error.fetchingDataError')} onClick={refetch} />
        </SpinnerWrapper>
      </>
    );
  }
  const itemsCount = data?.pages[0].eventsBy.count;
  const events = data?.pages[currentPageIndex]?.eventsBy.events;
  const loading = isLoading || isFetchingNextPage;
  const isTutorialBannerVisible = !loading && !itemsCount;

  return (
    <>
      <EventsView
        hasNextPage={hasNextPage || currentPageIndex < maxPageIndex}
        loading={loading}
        itemsCount={itemsCount as number}
        pageIndex={currentPageIndex}
        onNextPress={onNextPress}
        onPreviousPress={onPreviousPress}
        columns={columns}
        data={events || []}
        onSortingChanged={onSortingChanged}
        initialTableSorting={getTableSorting(orderBy)}
      />
      <VideoTutorialBanner
        text={t('videoTutorials.banner.events')}
        visible={isTutorialBannerVisible}
        testId={'EventsView#VideoTutorialBanner'}
      />
    </>
  );
};

export default EventsViewContainer;
